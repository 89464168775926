<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <date-range-input :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <v-spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <template v-else>
                <apex-chart
                    height="200"
                    :options="chartOptionsRssi"
                    :series="seriesRssi"
                />

                <apex-chart
                    height="200"
                    :options="chartOptionsSf"
                    :series="seriesSf"
                />

                <apex-chart
                    height="200"
                    :options="chartOptionsSnr"
                    :series="seriesSnr"
                />

                <apex-chart
                    height="200"
                    :options="chartOptionsCounter"
                    :series="seriesCounter"
                />
            </template>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

const baseChartOptions = {
    chart: {
        group: 'network',
        type: 'area',
        animations: {
            enabled: false,
        },
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
        },
    },
    stroke: {
        curve: 'straight',
    },
    markers: {
        size: 3,
    },
    dataLabels: {
        enabled: false,
    },
    colors: [process.env.VUE_APP_COLOR_PRIMARY],
    fill: {
        type: 'solid',
        opacity: 0,
    },
    xaxis: {
        type: 'datetime',
    },
    tooltip: {
        shared: false,
        x: {
            format: 'dd.MM.yy HH:mm:ss',
        },
    },
}

export default {
    name: 'NetworkChartView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            seriesCounter: [
                {
                    name: this.$t('counter'),
                    data: [],
                },
            ],
            seriesRssi: [
                {
                    name: this.$t('rssi'),
                    data: [],
                },
            ],
            seriesSf: [
                {
                    name: this.$t('sf'),
                    data: [],
                },
            ],
            seriesSnr: [
                {
                    name: this.$t('snr'),
                    data: [],
                },
            ],
            chartOptionsCounter: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'counter' },
                yaxis: [
                    {
                        min: 0,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('counter'),
                        },
                    },
                ],
            },
            chartOptionsRssi: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'rssi' },
                yaxis: [
                    {
                        max: -20,
                        min: -130,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('rssi'),
                        },
                    },
                ],
            },
            chartOptionsSf: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'sf' },
                yaxis: [
                    {
                        min: 7,
                        max: 12,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('sf'),
                        },
                    },
                ],
            },
            chartOptionsSnr: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'snr' },
                colors: [process.env.VUE_APP_COLOR_ACCENT],
                yaxis: [
                    {
                        max: 10,
                        min: -20,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('snr'),
                        },
                    },
                ],
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    methods: {
        async loadHistory({ startDate, endDate }) {
            this.isLoading = true
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            await this.loadData()
            this.isLoading = false
        },
        async loadData() {
            const results = []

            let url =
                'measurements/?' +
                `timestamp_min=${encodeURIComponent(this.startDate.format())}` +
                `&timestamp_max=${encodeURIComponent(this.endDate.format())}` +
                `&tracker=${this.id}` +
                '&fields=timestamp,network_data,counter' +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            this.seriesCounter[0].data = results.map(item => [
                item.timestamp,
                item.counter,
            ])
            this.seriesRssi[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.rssi,
            ])
            this.seriesSnr[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.snr,
            ])
            this.seriesSf[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.sf,
            ])
        },
    },
}
</script>

<i18n>
{
    "en": {
        "counter": "Counter",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    },
    "de": {
        "counter": "Counter",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    },
    "it": {
        "counter": "Counter",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    padding: 1rem 1rem 1rem 0;
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
}

.datepicker {
    width: 20%;
    min-width: 350px;
    border-right: $style-border;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
